<template>
    <div class="MyOrder">
        <van-tabs class="tabs" v-model:active="active" @click-tab="onClickTab">
            <van-tab title="今日"></van-tab>
            <van-tab title="昨日"></van-tab>
            <van-tab title="本周"></van-tab>
            <van-tab title="本月"></van-tab>
            <van-tab title="自定义"></van-tab>
        </van-tabs>

        <!-- <van-pull-refresh v-model="refreshing" @refresh="onRefresh"> -->
        <div class="data" v-show="dataList.length" id="MyOrderDrag">
            <div class="drag-container">
                <van-list class="list" v-model:loading="loading" :finished="finished" @load="onLoad" loading-text=" " :offset="1000">
                    <div class="item" v-for="item in dataList" :key="item.ID" @click="router.push({ path: '/MyOrderPage', query: { subID: item.ID } })">
                        <div class="top">
                            <p>
                                <span class="iconfont icon-a-iconpay_huaban1fuben47"></span>
                                <span>{{ item.order_state }}</span>
                            </p>
                            <p>{{ item.CREATE_DATE }}</p>
                        </div>

                        <span v-if="item.IS_SELL === 0" class="iconfont icon-a-payicon101_zhuanqu_huaban1fuben59"></span>
                        <span v-else class="iconfont icon-a-payicon101_zhuanqu_huaban1fuben60"></span>

                        <div class="bottom">
                            <p class="msg">{{ item.NICK_NAME }}</p>

                            <p class="money">
                                <span>$</span>
                                <span>{{ item.AMT }}</span>
                            </p>
                        </div>
                    </div>
                </van-list>
            </div>
        </div>
        <NoData v-show="dataList.length == 0">
            暂无更多信息记录
        </NoData>

        <!-- <img class="toTop" v-if="dataList.length" :src="require('@/assets/img/toTop.png')" @click="toTop" /> -->
        <!-- </van-pull-refresh> -->

        <van-action-sheet v-model:show="isShow" title="自定义筛选">
            <div class="content">
                <van-cell title="开始时间" is-link :value="startTimeVal" @click="showStartTime = true" />
                <van-cell title="结束时间" is-link :value="endTimeVal" @click="showEndTime = true" />
                <van-button class="btn" type="primary" block round @click="onSubmit">立即查询</van-button>
            </div>
        </van-action-sheet>

        <van-action-sheet v-model:show="showStartTime">
            <van-datetime-picker class="action-sheet" v-model="startDate" type="date" title="请选择年月日" :min-date="minDate" :max-date="maxDate" @confirm="startConfirm" @cancel="showStartTime = false" />
        </van-action-sheet>
        <van-action-sheet v-model:show="showEndTime">
            <van-datetime-picker class="action-sheet" v-model="endDate" type="date" title="请选择年月日" :min-date="minDate" :max-date="maxDate" @confirm="endConfirm" @cancel="showEndTime = false" />
        </van-action-sheet>
    </div>
</template>

<script>
import { reactive, toRefs, nextTick, onMounted, inject } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import NoData from '@/components/NoData.vue'
import { Toast } from 'vant'
import { getAllOrderList, getAllOrderExcel } from '@/api'
import { arrayUnique, toTop, getTodayDate, getYesterdayDate, getCurrentWeek, getCurrentMonth, getDate } from '@/assets/js/common.js'
import BScroll from '@better-scroll/core'

export default {
    components: {
        NoData
    },
    setup() {
        const state = reactive({
            refreshing: false,
            loading: true,
            finished: false,
            dataList: [],

            page: 1,
            total: 0,

            active: 0,
            s_dtt: getTodayDate()[0],
            e_dtt: getTodayDate()[1],

            isShow: false,

            startTimeVal: getTodayDate()[0],
            showStartTime: false,
            startDate: new Date(),

            endTimeVal: getTodayDate()[1],
            showEndTime: false,
            endDate: new Date(),

            minDate: new Date(2020, 0, 1),
            maxDate: new Date(2025, 10, 1),

            BScroll: null
        })

        const router = useRouter()
        const store = useStore()

        const width = inject('viewWidth', 0).value
        const height = inject('viewHeight', 0).value

        const Excel = () => {
            getAllOrderExcel({
                page: state.page,
                s_dtt: state.s_dtt,
                e_dtt: state.e_dtt
            })
        }

        const getData = () => {
            getAllOrderList({
                page: state.page,
                s_dtt: state.s_dtt,
                e_dtt: state.e_dtt
            }).then(res => {
                let data = res.data
                data.forEach(item => {
                    // 订单状态: 0订单销售中 1订单完成 2订单撤回 3暂停打币
                    if (item.ORDER_STATE === '0') item.order_state = '订单销售中'
                    if (item.ORDER_STATE === '1') item.order_state = '订单完成'
                    if (item.ORDER_STATE === '2') item.order_state = '订单取消'
                    if (item.ORDER_STATE === '3') item.order_state = '暂停打币'
                })

                state.total = res.page.total
                state.dataList = state.dataList.concat(data)
                state.dataList = arrayUnique(state.dataList, 'ID')

                state.refreshing = false
                state.loading = false
                if (state.total <= state.page) {
                    state.finished = true
                } else {
                    state.finished = false
                }

                nextTick(() => {
                    state.BScroll.refresh()
                })
            })
        }
        getData()

        // WebSocket
        window.connection.off('RefreshMyOrderList')
        window.connection.on('RefreshMyOrderList', () => {
            getData()
        })

        const onLoad = () => {
            state.page++
            getData()
        }

        const onRefresh = () => {
            state.refreshing = true
            state.loading = true
            state.finished = false

            state.page = 1
            getData()
        }

        const onClickTab = ({ title }) => {
            if (title === '自定义') {
                state.isShow = true
                return
            }

            if (title === '今日') [state.s_dtt, state.e_dtt] = getTodayDate()
            if (title === '昨日') [state.s_dtt, state.e_dtt] = getYesterdayDate()
            if (title === '本周') [state.s_dtt, state.e_dtt] = getCurrentWeek()
            if (title === '本月') [state.s_dtt, state.e_dtt] = getCurrentMonth()

            state.dataList = []
            state.page = 1
            getData()
        }

        const startConfirm = date => {
            state.startTimeVal = getDate(date)
            state.s_dtt = state.startTimeVal
            state.showStartTime = false
        }

        const endConfirm = date => {
            state.endTimeVal = getDate(date)
            state.e_dtt = state.endTimeVal
            state.showEndTime = false
        }

        const onSubmit = () => {
            state.isShow = false
            state.dataList = []
            state.page = 1
            getData()
        }

        const initBScroll = () => {
            state.BScroll = new BScroll('.data', {
                click: true,
                tap: true,
                probeType: 3
            })
        }

        onMounted(() => {
            document.getElementById('MyOrderDrag').style.height = height - 44 - 46 + 'px'
            initBScroll()
        })

        return {
            ...toRefs(state),
            router,
            onLoad,
            onRefresh,
            toTop,
            onClickTab,
            startConfirm,
            endConfirm,
            onSubmit,
            Excel
        }
    }
}
</script>

<style lang="scss" scoped>
// 下拉加载
.MyOrder ::v-deep .van-pull-refresh__track {
    min-height: calc(100vh - 46px);
}

.MyOrder {
    background: $background;
    position: relative;

    .data {
        width: 100%;
        overflow: hidden;
        box-sizing: border-box;

        .drag-container {
            padding: h(12) h(15);
        }

        .item {
            position: relative;
            overflow: hidden;
            height: h(105);
            background: #ffffff;
            border-radius: 8px;
            margin-bottom: h(12);

            .top {
                display: flex;
                justify-content: space-between;
                margin: h(15) w(15) 0 w(20);
                height: h(20);
                line-height: h(20);
                font-size: 12px;
                color: #3366ee;

                .iconfont {
                    font-size: 25px;
                    margin-right: w(10);
                    vertical-align: bottom;
                }

                & p:last-child {
                    color: #828282;
                }
            }

            .icon-a-payicon101_zhuanqu_huaban1fuben59 {
                position: absolute;
                top: h(49);
                left: w(15);
                width: w(32);
                height: h(32);
                line-height: h(32);
                background: linear-gradient(318.61deg, #ff8329 -2.85%, #ffbb64 96.65%);
                color: #ffffff;
                font-size: 20px;
                text-align: center;
                border-radius: 8px;
            }

            .bottom {
                display: flex;
                justify-content: space-between;

                .msg {
                    margin: h(15) w(15) 0 w(60);
                    font-weight: bold;
                    font-size: 16px;
                    color: #000000;
                }

                .money {
                    margin: h(15) w(15) 0 0;
                    font-weight: bold;
                    font-size: 16px;
                    color: #3493ff;

                    & span:last-child {
                        margin-left: w(7);
                    }
                }
            }
        }
    }

    .toTop {
        position: fixed;
        right: h(10);
        bottom: h(10);
    }

    .content {
        padding: 10px 16px h(30);
        text-align: left;

        .btn {
            @include btn();

            margin-top: h(50);
        }
    }

    .action-sheet {
        margin: 10px;
    }
}
</style>
